import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { ErrorOption } from 'react-hook-form'
import { TRACKING_EVENTS } from '~/lib/constants'
import { getCustomer, login } from '~/lib/handle-api'
import { setAccessToken } from '~/lib/token'

import { CustomerAccessTokenCreateInput } from '@unlikelystudio/ecommerce-typed/types/shopify'

import { useTracking } from '~/providers/TrackingProvider'

import useOnMutationError from '~/hooks/account/useOnMutationError'
import useCurrency from '~/hooks/useCurrency'

export default function useShopifyLoginMutation(
  mutationOptions?: UseMutationOptions<any, any, any>,
  setError?: (name?: string, error?: ErrorOption) => void,
) {
  const onMutationError = useOnMutationError(setError)
  const currency = useCurrency()
  const tracking = useTracking()

  return useMutation<any, any, any>(
    async ({ email, password }: CustomerAccessTokenCreateInput) => {
      return await login({ store: currency, payload: { email, password } })
    },
    {
      ...mutationOptions,
      onSuccess: async (data, ...rest) => {
        //TODO: standardize error response
        const error = data?.errors?.[0]
        if (error) {
          return onMutationError(error, rest, mutationOptions?.onError)
        }
        const profile = await getCustomer({
          store: currency,
          accessToken: data?.jwt,
        })
        tracking.emit(TRACKING_EVENTS.LOGIN, { id: profile?.id ?? null })
        setAccessToken(data.jwt, currency)
        mutationOptions.onSuccess(data, ...rest)
      },
      onError: (err, ...rest) => {
        onMutationError(err, rest, mutationOptions?.onError)
      },
    },
  )
}
